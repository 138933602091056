import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import ReactPaginate from "react-paginate";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Modal from "@mui/joy/Modal";
import Moment from "moment";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";

function Dashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalData, setModalData] = useState("");
  const [emailValidate, setEmailValidate] = useState(false);
  const [validationErr, setValidationErr] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [amount, setAmount] = useState("");
  const [wallet, setWallet] = useState("p2p_wallet"); // Default to p2p_wallet
  const [validationErrors, setValidationErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [tfa_code, setTfa_code] = useState("");

  const validateAmount = () => {

    console.log("validation ")
    let errors = {};
    if (amount < 1 || amount > 1000000) {
      errors.amount = "Amount must be between 1 and 1,000,000";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validate2fcode = () => {
    let errors = {};
    if (tfa_code == null || tfa_code == "" || tfa_code == undefined) {
      errors.tfa_code = "";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleWalletChange = (e) => {
    setWallet(e.target.value);
  };

  const handle2faChange = (e) => {
    setTfa_code(e.target.value);
  };

  const handleSubmit = async (actionType) => {
    console.log("jbcujwujew")
    
    if (!validateAmount()) return;
    else if(!validate2fcode()) return;

    const obj = {
      amount: Number(amount),
      wallet: wallet,
      action: actionType, // "add" or "minus"
      id: modalData._id,
      tfa_code: tfa_code
    };
    try {

      const data = {
        apiUrl: apiService.Addbalance,
        payload: obj,
      };
      const resp = await postMethod(data);
      setLoader(true);
      setIsModalOpen(false);
      if (resp.status) {
        toast.success(resp.message);
        setAmount("");
        setLoader(false);
      } else {
        toast.error(resp.message);
        setAmount("");
        setLoader(false);

      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      toast.error("Something went wrong, Please try again later!");
    }
  };

  useEffect(() => {
    getUserDetails(currentPage);
  }, [currentPage, filterKeyword]);

  const getUserDetails = async (page = 1) => {
    const data = {
        apiUrl: apiService.activatedUserList,
        payload: { page, limit: 10, keyword: filterKeyword }, // Include the keyword here
    };
    const response = await postMethod(data);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
        setUsersData(response.data);
        setTotalPages(response.totalPages);
        setFilteredUsers(response.data); // No need to filter here, since we're now getting all users with the keyword
    } else {
        setUsersData([]);
        setFilteredUsers([]);
    }
};
  const handleStatusChange = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === 0 ? 1 : 0;
      const response = await postMethod({
        apiUrl: "adminapi/changeUserAccountStatus",
        payload: { _id: userId, status: newStatus },
      });

      if (response.status) {
        toast.success('User Account Status Updated Successfully');
        getUserDetails(currentPage); // Refresh user data after status change
      } else {
        toast.error('Something Went Wrong. Please Try Again later');
      }
    } catch (error) {
      toast.error('Error updating status. Please try again later.');
    }
  };

  const openmode = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const openmode1 = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...modalData, [name]: sanitizedValue };
    setModalData(formData);
    validate(formData);
  };

  const validate = (values) => {
    const errors = {};
    const username = values.email.split("@")[0];

    if (!values.email) {
      errors.email = "Email is a required field!";
      setEmailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setEmailValidate(true);
    } else if (username.length < 4 || username.length > 30) {
      errors.email = "Email Username must be between 4-30 characters long";
      setEmailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email =
        " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setEmailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setEmailValidate(true);
    } else if (values.email.length > 50) {
      errors.email = "Email address is too long!";
      setEmailValidate(true);
    } else {
      setEmailValidate(false);
    }
    setValidationErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(modalData);
    if (!emailValidate) {
      console.log(modalData);
      const data = {
        apiUrl: apiService.changeEmail,
        payload: modalData,
      };
      const resp = await postMethod(data);

      if (resp.status) {
        toast.success(resp.Message);
        setIsModalOpen(false);
        getUserDetails(currentPage);
      } else {
        toast.error(resp.Message);
        setIsModalOpen(false);
        getUserDetails(currentPage);
      }
    } else {
      console.log("Required all fields");
    }
  };

  const handleFilterChange = (e) => {
    setFilterKeyword(e.target.value);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="px-2 my-4 transaction_padding_top tops">
                <div className="headerss">
                  <span className="dash-head">User Management</span>
                  <div>
                    <input
                      className="filters"
                      placeholder="Filter"
                      value={filterKeyword}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
                <div className="table-responsive my-5 trans-table">
                  <table className="w_100">
                    <thead className="trans-head">
                      <tr>
                        <th>S.No</th>
                        <th>Email</th>
                        <th>User ID</th>

                        <th>Username</th>
                        <th>Mobile Number</th>
                        <th>Sponsor</th>
                        <th>Date & Time</th>
                        <th>Status Action</th>
                        <th>Action</th>
                        <th>Add Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.length > 0 ? (
                        filteredUsers.map((item, i) => (
                          <tr key={item._id}>
                            <td>
                              <span className="plus_14_ff">{i + 1}</span>
                            </td>
                            <td>
                              <span className="plus_14_ff">{item.email}</span>
                            </td>
                            
                            <td>
                              <span className="plus_14_ff">{item.internalID}</span>
                            </td>
                            <td>
                              <span className="plus_14_ff">{item.username}</span>
                            </td>

                            <td>
                              <span className="plus_14_ff">{item.mobileNumber}</span>
                            </td>
                            <td>
                              <span className="plus_14_ff">{item.referral}</span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                {Moment(item.datetime).format("lll")}
                              </span>
                            </td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.status === 1}
                                  onChange={() => handleStatusChange(item._id, item.status)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                <i
                                  className="fa-regular fa-pen-to-square"
                                  onClick={() => openmode(item)}
                                ></i>
                              </span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                <i
                                  className="fa-solid fa-circle-plus"
                                  onClick={() => openmode1(item)}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div className="empty_data">
                              <div className="plus_14_ff">No Records Found</div>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr className="text-center">
                        <td colSpan="8">
                          <div className="paginationcss">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={totalPages}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination pagination-md justify-content-center"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              minWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            ></Typography>
            <Typography id="modal-desc">
              <h3 className="connect_a_connect_text">Add WXDL Balance</h3>
              <div className="ycho_inner mt-4">
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Amount</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Enter amount"
                    type="number"
                    name="amount"
                    value={amount}
                    onChange={handleInputChange}
                    pattern="[0-9]*"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                  />
                  {validationErrors.amount && (
                    <p className="errorcss">{validationErrors.amount}</p>
                  )}
                </div>
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Wallet</span>
                  <select
                    className="admin_login_imput"
                    name="wallet"
                    value={wallet}
                    onChange={handleWalletChange}
                  >
                    <option value="p2p_wallet">P2P Wallet</option>
                    <option value="smart_wallet">Smart Wallet</option>
                  </select>
                </div>


                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">2FA Code</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Enter 2FA Code"
                    type="number"
                    name="tfa_code"
                    pattern="^\d{6}$"
                    required
                    maxLength="6"
                    onChange={handle2faChange}
                  />
                  {validationErrors.tfa_code && (
                    <p className="errorcss">{validationErrors.tfa_code}</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-around w_100">
                {modalData.balance_status == 1 ? (
                  loader === true ? (
                  <div className="lan_had_con">
                    <span className="con_lan_con">Loading</span>
                  </div>
                  ) : (
                    <div className="lan_had_con" onClick={() => handleSubmit("add")}>
                    <span className="con_lan_con">Add</span>
                  </div>
                  )
                ) : ("")}
                {loader === true ? (
                <div className="lan_had_con">
                  <span className="con_lan_con">Loading</span>
                </div>
                ) : (
                  <div className="lan_had_con" onClick={() => handleSubmit("minus")}>
                  <span className="con_lan_con">Minus</span>
                </div>
                )}
              </div>
            </Typography>
          </Sheet>
        </Modal>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={ModalOpen}
          onClose={() => setModalOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              minWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            ></Typography>
            <Typography id="modal-desc">
              <h3 className="connect_a_connect_text">Edit User Details</h3>
              <div className="ycho_inner mt-4">
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Email</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Enter email"
                    value={modalData.email}
                    onChange={handleChange}
                    type="text"
                    name="email"
                  />
                  {validationErr && validationErr.email && (
                    <p className="errorcss">{validationErr.email}</p>
                  )}
                </div>
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Phone Number</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Enter mobileNumber"
                    onChange={handleChange}
                    name="mobileNumber"
                    value={modalData.mobileNumber}
                    type="number"
                  />
                </div>
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Name</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Enter name"
                    value={modalData.username}
                    onChange={handleChange}
                    type="text"
                    name="username"
                  />
                </div>
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Password</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Password"
                    onChange={handleChange}
                    name="password"
                    value={modalData.password}
                    type="text"
                  />
                </div>
                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">Transaction Password</span>
                  <input
                    className="admin_login_imput"
                    placeholder="Transaction Password"
                    onChange={handleChange}
                    name="Transactionpassword"
                    value={modalData.Transactionpassword}
                    type="text"
                  />
                </div>

                <div className="hotpic_bot_hd">
                  <span className="hero_sub_inner">2FA Key</span>
                  <input
                    className="admin_login_imput"
                    name="tfaenablekey"
                    value={modalData.tfaenablekey}
                    type="text"
                    disabled
                  />
                </div>

                
              </div>
              <div className="d-flex justify-content-center w_100">
                <div className="lan_had_con">
                  <span className="con_lan_con" onClick={formSubmit}>Save</span>
                </div>
              </div>
            </Typography>
          </Sheet>
        </Modal>
      </div>
    </div>
  );
}

export default Dashboard;
